import { render, staticRenderFns } from "./PosList.vue?vue&type=template&id=2d8e8a95&scoped=true"
import script from "./PosList.vue?vue&type=script&lang=js"
export * from "./PosList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d8e8a95",
  null
  
)

export default component.exports